import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Flex, Skeleton, UserMenuItem } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import json from '../../../config/constants/tokenLists/pancake-default.tokenlist.json'

interface ProfileUserMenuItemProps {
  isLoading: boolean
  hasProfile: boolean
  getWalletInfo
}

const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.failure};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

const ProfileUserMenuItem: React.FC<ProfileUserMenuItemProps> = ({ isLoading, hasProfile, getWalletInfo }) => {
  const { t } = useTranslation()

  return null

}



export default ProfileUserMenuItem
